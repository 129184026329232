.header-view {
    .header {
        margin: 10px;
        width: 100%;
        height: 60px;
        padding: 0 20px;
    }

    .menuList {
        margin-left: 44px;

        .menuItem {
            margin-left: 30px;
            padding-bottom: 6px;
            cursor: pointer;
            position: relative;

            &:first-child {
                margin-left: 0;
            }

            .menuItemActive {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -10px;
                width: 20px;
                height: 4px;
                border-radius: 2px;
                background: #09F7FF;
            }
        }
    }

    .balanceList {
        .balanceItem {
            padding: 0 10px;
            height: 30px;
            background: rgba(255, 255, 255, 0.2);
            cursor: pointer;
        }
    }

    .connectBtn {
        width: 140px;
        height: 36px;
        border: 1px solid rgba(74, 178, 238, 0.3);
        margin-left: 60px;
        cursor: pointer;

        &:hover {
            color: #FFFFFF;
            background: linear-gradient(107deg, #4AB2EE 8%, #4AB2EE 8%, #864AEE 93%, #864AEE 93%);
            border: 1px solid rgba(74, 178, 238, 0.3);
        }
    }
}

.menuBox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(209deg, #001413 0%, #001413 0%, #4574F6 38%, #6487D9 64%, #B829F1 99%, #B829F1 99%);

    .logo {
        height: 32px;
    }

    .close {
        margin-right: 18px;
    }

    .navList {
        margin-top: 40px;
        padding: 0 10px;

        .navItem {
            &:first-child {
                .navItemContent {
                    margin-top: 0;
                }
            }

            .navItemContent {
                width: 100%;
                height: 48px;
                background: rgba(0, 0, 0, 0.5);
                margin-top: 10px;
            }
        }

    }

    .balance {
        padding: 0 10px;

        .balanceItemBox {
            flex: 1;
            padding: 15px 10px;
            background: rgba(0, 0, 0, 0.5);

            .balanceItem {
                padding-bottom: 14px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }
        }
    }
}

.MuiLink-root:hover {
    color: #FFFFFF !important;
}

.MuiLink-root.active {
    text-decoration: none !important;
}

@media only screen and (max-width: 1020px) {
    .header-view {
        .header {
            margin: 10px 0;
            padding: 0 10px;
        }

        .connectBtn {
            width: 110px;
            margin-left: 0;
        }
    }
}


@media only screen and (max-width: 321px) {
    .menuBox {
        overflow: scroll;
        padding-bottom: 20px;
    }
}