.header-view2 {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #000000 0%, #000000 0%, rgba(0, 0, 0, 0.00) 100%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 99;
    .header {
        height: 70px;
        padding-left: 32px;
        padding-right: 30px;
        .navList {
            .navItem {
                margin-left: 60px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .playBg {
            margin-left: 36px;
            width: 130px;
            height: 40px;
            background-image: url(../../assets/images/gw/playBg.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
}

@media only screen and (max-width: 1020px) {
    .header-view2 {
        .header {
            padding: 0 10px;
        }
    }
}