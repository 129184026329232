* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 1.5px;
}

#root {
  position: relative;
}

.font-Pricedown-Bl {
  font-family: "Pricedown-Bl";
  font-weight: 900;
  letter-spacing: 1.5px;
}

.font-Montserrat-VariableFont {
  font-family: "Montserrat-VariableFont" !important;
  letter-spacing: initial;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Pricedown-Bl";
  transition: all ease 0.33ms;
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.container2 {
  width: 1020px;
  max-width: 100%;
}

.bg1 {
  background: #000000;
}

.bg2 {
  background: #2C2C2C;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px !important;
}


.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20PX;
}

.font-24 {
  font-size: 24PX;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-34 {
  font-size: 34px;
}

.font-36 {
  font-size: 36px;
}

.font-48 {
  font-size: 48px;
}

.font-72 {
  font-size: 72px;
  line-height: 86px;
}

.font-weight-b {
  // font-weight: bold;
  font-variation-settings: "wght" 700;
}

.font-weight-9 {
  font-weight: 900;
}

.font-weight-4 {
  font-weight: 400;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: #000000;
}

.color3 {
  color: #198AD0;
}

.color4 {
  color: #FFDE3B;
}

.color5 {
  color: #008CFF;
}

.color6 {
  color: rgba(255, 255, 255, 0.5);
}

.color7 {
  color: #20A2FF;
}

.color8 {
  color: #FFAB00;
}

.color9 {
  color: #14E3FA;
}

.color10 {
  color: #FAD414;
}

.color11 {
  color: #54C3FF;
}

.color12 {
  color: #FF12F3;
}

.color13 {
  color: #15E0FF;
}

.color14 {
  color: #FFF315;
}

.color15 {
  color: #FFCC00;
}

.color16 {
  color: #FFD114;
}

.color17 {
  color: #17FFFF;
}

.color18 {
  color: #00C8FF;
}

.color19 {
  color: #FFB300;
}

.color20 {
  color: #00FFB7;
}

.color21 {
  color: #07F7E7;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-1 {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.border-b {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.flex-1 {
  flex: 1;
}

a {
  text-decoration: none;
}

.img {
  max-width: 100%;
}

.app-container {
  position: relative;
}

.word-break {
  word-break: break-word;
}

.box {
  background: linear-gradient(#44A3DC 100%, #FFE500 100%);
  padding: 1px;
}

@media only screen and (max-width: 1020px) {
  .container,.container2 {
    width: 500px;
  }
}